@import "assets/scss/colors.scss";
@import "assets/scss/mixins.scss";

.AboutMe {
  color: $white;
  position: absolute;
  translate: 0 -110%;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: scroll;

  @include transitionHandler(0, 0, 0);

  &--move-to-new-area {
    translate: 110% -40%;
  }

  &--move-to-new-area-home {
    translate: 0 -110%;
  }

  &__textContainer {
    margin-bottom: 3rem;
  }

  &__text {
    white-space: pre-line;
    text-align: center;
    min-height: 80%;
  }

  &__btn {
    display: flex;
    justify-content: center;
  }
}

@import "/src/assets/scss/colors";

.Button {
  position: relative;
  overflow: hidden;
  color: #fff;
  z-index: 0;
  padding: 5px;
  line-height: 0;
  // display: inline-block;
  margin: 10px;
  background-color: transparent;
  border: none;
  border-radius: 25px;

  &:disabled {
    // background-color: ;

    & .Button--main {
      background-color: $grey;
      color: #cdcdcd;
    }
  }

  &--spacer {
    content: "";
    position: absolute;
    z-index: -1;
    inset: 0;
    border-radius: 25px;
    padding: 2px;
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    transition: background-color 0.5s linear;
  }

  &:not(:disabled):hover &--spacer::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 400px;
    height: 400px;
    background: linear-gradient($olive 50%, transparent 0);
    translate: -50% -50%;
    animation: rotateBorder 2s linear infinite;
  }

  &:not(:disabled):hover &--main {
    background-color: darken($olive, 10%);
  }

  &--main {
    background-color: $olive;
    padding: 0.5rem;
    border-radius: 25px;
    transition: background-color 0.1s linear;
  }

  @keyframes rotateBorder {
    100% {
      rotate: 360deg;
    }
  }
}

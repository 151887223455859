@import "assets/scss/colors.scss";
@import "assets/scss/mixins.scss";

.Blob {
  display: flex;
  align-items: center;

  &__blob {
    background-color: $beige;
    top: 50%;
    left: 50%;
    height: 40rem;
    width: 27rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    color: $brown;
    font-size: 2rem;
    transition: height 0.2s, width ease 0.2s, border-radius linear 6s;
    transform-origin: center;
    text-decoration: none;
    box-shadow: 0 0 10px 10px rgba($color: $black, $alpha: 0.1);
    margin: 10px;

    &--no-transition {
      &:hover {
        @include bounceAnimation;
      }
    }

    &--clickable {
      &:hover {
        z-index: 11;
        cursor: url(https://cdn.custom-cursor.com/db/13859/32/cute-crayfish-pointer.png),
          pointer !important;

        * {
          cursor: url(https://cdn.custom-cursor.com/db/13859/32/cute-crayfish-pointer.png),
            pointer !important;
        }
      }
    }
  }
}

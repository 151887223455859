$white: rgb(255, 248, 245);
$blue: #7ea8be;
$orange: #f7a578;
$yellow: #dab27a;
$beige: #fbe8da;
$brown: #634d40;
$black: rgb(21, 10, 2);

// new colours
$sand: #f0d59c;
$oceanBlue: #3f7f9e;
$olive: #a19936;
$taupe: #97857b;
$grey: #98918d;
$red: #ac2828;

@import "assets/scss/colors.scss";
@import "assets/scss/mixins.scss";

.Skills {
  color: $white;
  position: absolute;
  translate: var(--translate);
  height: 100%;
  width: 100%;

  @include transitionHandler(0, 0, 0);

  &--move-new-area {
    translate: -110% -80%;
  }

  &__container {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__blob-container {
    display: flex;
    justify-content: center;
    text-transform: capitalize;
    text-align: center;
    flex-wrap: wrap;
    padding: 5rem;
    overflow: auto;

    @media (min-width: 1440px) {
      max-width: 75%;
      margin: 0 auto;
    }

    [class~="Section__container"] {
      padding: 1rem;
      width: calc(100% - 2rem);
      height: calc(100% - 2rem);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__blob {
    & + & {
      margin: 0 1.5rem 1.5rem 1.5rem;
    }
  }

  &__small-text {
    font-size: 20px;
  }
}

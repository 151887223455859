@import "assets/scss/colors.scss";

html {
  width: 100%;
  height: 100%;
}

* {
  cursor: url(https://cdn.custom-cursor.com/db/13860/32/cute-crayfish-cursor.png),
    default;
}

body,
input,
textarea {
  margin: 0;
  font-family: bodyFont, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  height: 100%;
  width: 100%;
  background-color: $beige;
}

a {
  color: $brown;
  text-decoration: none;

  &:hover {
    color: darken($color: $brown, $amount: 20%);
  }
}

a,
button:not(:disabled) {
  cursor: url(https://cdn.custom-cursor.com/db/13859/32/cute-crayfish-pointer.png),
    pointer;
  font-family: bodyFont, greekBaseFont, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;

  * {
    cursor: url(https://cdn.custom-cursor.com/db/13859/32/cute-crayfish-pointer.png),
      pointer;
    font-family: bodyFont, greekBaseFont, -apple-system, BlinkMacSystemFont,
      "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
      "Droid Sans", "Helvetica Neue", sans-serif;
  }
}

input,
textarea {
  cursor: text;
}

button {
  font-size: 16px;
  letter-spacing: 0.2px;
  font-family: bodyFont, greekBaseFont, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;

  * {
    font-family: bodyFont, greekBaseFont, -apple-system, BlinkMacSystemFont,
      "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
      "Droid Sans", "Helvetica Neue", sans-serif;
  }
}

button:disabled {
  font-size: 16px;
  letter-spacing: 0.2px;
  font-family: bodyFont, greekBaseFont, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;

  * {
    font-family: bodyFont, greekBaseFont, -apple-system, BlinkMacSystemFont,
      "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
      "Droid Sans", "Helvetica Neue", sans-serif;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  width: 100%;
  height: 100%;
}

@font-face {
  font-family: bodyFont;
  src: url(assets/fonts/Employed.ttf);
}

@font-face {
  font-family: bobaFont;
  src: url(assets/fonts/BobaMilky.ttf);
}

@font-face {
  font-family: greekBobaFont;
  src: url(assets/fonts/AthenaVKF.otf);
}

@font-face {
  font-family: greekBaseFont;
  src: url(assets/fonts/RegularGreek.ttf);
}

@mixin baseFont {
  font-family: bodyFont, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

@mixin baseFontGr {
  font-family: greekBaseFont, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

@mixin supportFont {
  font-family: bobaFont, bodyFont, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

@mixin supportFontGr {
  font-family: greekBobaFont, bodyFont, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
}

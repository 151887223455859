@import "assets/scss/colors.scss";
@import "assets/scss/mixins.scss";

.Home {
  color: $white;
  position: absolute;
  translate: var(--translate);
  width: 100%;
  height: 100%;
  display: flex;
  overflow: auto;
  @include transitionHandler(0, 0, 0);

  &--move-new-area {
    translate: 0 120%;
  }

  &--move-new-area-skills {
    translate: 110% 70%;
  }

  &--move-new-area-contact {
    translate: 100% 0%;
  }

  &__container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media (min-width: 620px) {
      justify-content: center;
    }
  }

  &__blob-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__blob {
    [class~="Section__container"] {
      padding: 3rem;
      width: calc(100% - 6rem);
      height: calc(100% - 6rem);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__btn {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
}

@import "assets/scss/colors.scss";

.Section {
  height: 100%;
  width: 100%;
  overflow: auto;

  &__container {
    width: calc(100% - 9rem);
    height: calc(100% - 10rem);
    padding: 5rem 4.5rem;
    position: relative;

    &--white {
      background-color: rgba($white, 0.5);
    }

    &--blue {
      background-color: rgba($blue, 0.5);
    }

    &--orange {
      background-color: rgba($orange, 0.5);
    }

    &--yellow {
      background-color: rgba($yellow, 0.5);
    }

    &--beige {
      background-color: rgba($beige, 0.5);
    }

    &--brown {
      background-color: rgba($brown, 0.5);
    }

    &--black {
      background-color: rgba($black, 0.5);
    }

    &--transparent {
      background-color: transparent;
    }
  }
}

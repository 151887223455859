@use "../../index.scss" as typography;

.Typography {
  @include typography.baseFont;
  margin: 0;
  padding: 0;

  &--bold {
    @include typography.baseFont;
    font-weight: bold;
  }

  &--dtl {
    font-size: 82px;
    line-height: 74px;
  }

  &--dtl-en {
    @include typography.baseFont;
  }

  &--dtl-gr {
    @include typography.baseFontGr;
    letter-spacing: -0.9px;
  }

  &--dtm {
    font-size: 64px;
    line-height: 60px;
  }

  &--dtm-en {
    @include typography.baseFont;
  }

  &--dtm-gr {
    @include typography.baseFontGr;
    letter-spacing: -0.9px;
  }

  &--dts {
    font-size: 48px;
    line-height: 46px;
  }

  &--dts-en {
    @include typography.baseFont;
  }

  &--dts-gr {
    @include typography.baseFontGr;
    letter-spacing: -0.9px;
  }

  &--tl {
    font-size: 32px;
    line-height: 34px;
  }

  &--tl-en {
    @include typography.baseFont;
  }

  &--tl-gr {
    @include typography.baseFontGr;
    letter-spacing: -0.9px;
  }

  &--tm {
    font-size: 28px;
    line-height: 30px;
  }

  &--tm-en {
    @include typography.baseFont;
  }
  &--tm-gr {
    @include typography.baseFontGr;
    letter-spacing: -0.9px;
  }

  &--ts {
    font-size: 24px;
    line-height: 28px;
  }

  &--ts-en {
    @include typography.baseFont;
  }

  &--ts-gr {
    @include typography.baseFontGr;
    letter-spacing: -0.9px;
  }

  &--bcl {
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 20px;
  }

  &--bcl-en {
    @include typography.baseFont;
  }

  &--bcl-gr {
    @include typography.baseFontGr;
  }

  &--bcm {
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 20px;
  }

  &--bcm-en {
    @include typography.supportFont;
  }

  &--bcm-gr {
    @include typography.supportFontGr;
  }

  &--bcx {
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 0;
  }

  &--bcx-en {
    @include typography.baseFont;
    @include typography.baseFontGr;
  }

  &--bcx-gr {
    @include typography.baseFontGr;
  }

  &--bcs {
    @include typography.supportFont;

    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 18px;
  }

  &--bcs-en {
    @include typography.supportFont;
  }

  &--bcs-gr {
    @include typography.supportFontGr;
  }

  &--mc {
    @include typography.baseFont;

    font-size: 12px;
    letter-spacing: 0.6px;
    line-height: 16px;
  }

  &--mc-en {
    @include typography.baseFont;
  }

  &--mc-gr {
    @include typography.baseFontGr;
    letter-spacing: -0.9px;
  }
}

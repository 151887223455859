@import "assets/scss/colors.scss";

.Input {
  &__Element {
    width: calc(100% - 30px);
    min-height: 2rem;
    background-color: $beige;
    border: 1px solid $taupe;
    border-radius: 5px;
    box-shadow: 0 0 10px 10px rgba($color: $black, $alpha: 0.1);
    padding: 0 15px;

    &:focus-visible {
      outline: 1px solid $taupe;
    }

    &--textarea {
      min-height: 200px;
      padding: 15px;
    }
  }

  &__Label {
    margin-bottom: 0.5rem;
  }

  &__SupportText {
    position: absolute;
    margin-top: 0.5rem;
  }

  &--error {
    .Input__SupportText {
      color: $red;
    }

    .Input__Element {
      border-color: $red;
    }
  }
}

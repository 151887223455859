@import "assets/scss/colors.scss";
@import "assets/scss/mixins.scss";

.ContactForm {
  position: absolute;
  width: 100%;
  height: 100%;
  color: $white;
  translate: var(--translate);
  @include transitionHandler(0, 0, 0);

  &--home-position {
    translate: -100% 0;
  }

  &__Section {
    max-width: 600px;
    margin: auto;
  }

  &__SectionContainer {
    display: flex;
    flex-direction: column;
    position: relative;

    & > :not(:first-child) {
      margin-top: 2rem;
    }
  }

  &__Title {
    text-shadow: 0 0 1px $black;
    border-radius: 10px;
    padding: 5px;
    text-align: center;
    color: $brown;
  }

  &__Input {
    color: $brown;
  }

  &__CloseBtn {
    position: absolute;
    top: 5rem;
    right: 0;

    & > :nth-child(2) {
      padding: 1rem;
    }

    &::before {
      content: "";
      height: 1px;
      width: calc(100% - 30px);
      position: absolute;
      background-color: $white;
      top: 50%;
      left: 50%;
      translate: -50%;
      rotate: -45deg;
    }

    &::after {
      content: "";
      height: 1px;
      width: calc(100% - 30px);
      position: absolute;
      background-color: $white;
      top: 50%;
      left: 50%;
      translate: -50%;
      rotate: 45deg;
    }
  }
}

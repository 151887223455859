@import "assets/scss/colors.scss";
@import "assets/scss/mixins.scss";

.LandingPage {
  @include pageLoadAnimation;
  height: 100%;
  width: 100%;

  &__container {
    display: flex;
    justify-content: center;
    position: relative;
    height: 100%;
    width: 100%;

    @include transitionHandler(0, 0, -120% -120%);
  }

  &__link-container {
    height: 30rem;
    text-align: center;
  }

  &__link-title-container {
    margin-top: 3rem;
    &--small {
      margin-top: 1rem;
    }
  }

  &__link-title {
    color: $brown;
    margin-top: 3rem;
  }

  &__link-desc {
    color: $brown;
    margin-top: 3rem;
  }

  &__link-clock {
    color: $taupe;
  }

  &__btns {
    margin-top: 7rem;
  }
}

@mixin pageLoadAnimation($reverse: false) {
  animation: pageLoad 1s forwards;

  @if $reverse == true {
    animation-direction: reverse;
  }

  @keyframes pageLoad {
    0% {
      opacity: 0;
      @if $reverse == true {
        z-index: -1;
      }
    }
    1% {
      @if $reverse == true {
        z-index: 1;
      }
    }
    100% {
      opacity: 1;
    }
  }
}

@mixin pageTransitionFromCenterColorAnimation(
  $height: 0,
  $width: 0,
  $backgroundColor,
  $color: white
) {
  animation: pageChange 0.2s forwards;

  @keyframes pageChange {
    0% {
      height: $height;
      width: $width;
      color: color;
      background-color: rgba($color: $backgroundColor, $alpha: 1);
    }
    75% {
      background-color: rgba($color: $backgroundColor, $alpha: 0.5);
    }
    100% {
      height: 100%;
      width: 100%;
      color: rgba($color, 0);
      border-radius: 0;
      background-color: rgba($color: $brown, $alpha: 0.5);
    }
  }
}

@mixin bounceAnimation {
  animation: bounceSize 1.5s ease-in-out infinite;

  @keyframes bounceSize {
    0%,
    100% {
      padding: 0;
      margin: 10px;
    }
    50% {
      padding: 10px;
      margin: 0;
    }
  }
}

@mixin transitionHandler($top: 50%, $left: 50%, $translate: -50% -50%) {
  transition: translate 0.6s ease-in-out;

  &--transition {
    transition: translate 0.6s ease-in-out;
  }

  &--no-transition {
    transition: none;
  }

  &--move {
    top: $top;
    left: $left;
    translate: $translate;
  }

  &--load-page {
    @include pageLoadAnimation;
  }
}

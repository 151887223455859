@import "assets/scss/mixins.scss";
@import "assets/scss/colors.scss";

.App {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;

  &__background {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
    scale: 2.5;
    object-position: center;
    position: absolute;
    top: 0;
    left: 0;
    transition: translate 0.6s linear;

    @include transitionHandler;
  }

  &__overlay {
    width: 100%;
    height: 100%;
    background-color: $beige;
    position: absolute;
    z-index: 1;
    @include pageLoadAnimation(true);
  }
}
